import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

import './basicTemplate.scss';

export default function Template({ pageContext }) {
  const { title, html } = pageContext;

  return (
    <Layout>
      <SEO title={title} />
      <div className="st-p-landing__simple-hero">
        <div className="container">
          <h1 className="st-p-landing__hero-title">{title}</h1>
        </div>
      </div>
      <div className="st-t-basic__main">
        <div className="container">
          <div className="content">
            <div className="blog-post-content" dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>
      </div>
    </Layout>
  );
}
